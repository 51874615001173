<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_biiii_723_3432)">
      <mask id="path-1-inside-1_723_3432" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.8295 206.829L249.564 74.2676C253.321 71.2441 258.676 71.2441 262.434 74.2676L427.17 206.829C429.592 208.777 431 211.718 431 214.826V430.7C431 436.369 426.404 440.964 420.735 440.964H307V334.661C307 320.489 295.511 309 281.339 309H235.661C221.489 309 210 320.489 210 334.661V440.964H91.2646C85.5956 440.964 81 436.369 81 430.7V214.826C81 211.718 82.4081 208.777 84.8295 206.829Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.8295 206.829L249.564 74.2676C253.321 71.2441 258.676 71.2441 262.434 74.2676L427.17 206.829C429.592 208.777 431 211.718 431 214.826V430.7C431 436.369 426.404 440.964 420.735 440.964H307V334.661C307 320.489 295.511 309 281.339 309H235.661C221.489 309 210 320.489 210 334.661V440.964H91.2646C85.5956 440.964 81 436.369 81 430.7V214.826C81 211.718 82.4081 208.777 84.8295 206.829Z"
        fill="url(#paint0_linear_723_3432)"
      />
      <path
        d="M249.564 74.2676L248.92 73.4679V73.4679L249.564 74.2676ZM84.8295 206.829L84.186 206.029H84.186L84.8295 206.829ZM262.434 74.2676L261.79 75.0673L262.434 74.2676ZM427.17 206.829L427.814 206.029L427.17 206.829ZM307 440.964H305.974V441.991H307V440.964ZM210 440.964V441.991H211.026V440.964H210ZM248.92 73.4679L84.186 206.029L85.473 207.628L250.207 75.0673L248.92 73.4679ZM263.077 73.4679C258.944 70.1421 253.053 70.1421 248.92 73.4679L250.207 75.0673C253.589 72.3462 258.409 72.3462 261.79 75.0673L263.077 73.4679ZM427.814 206.029L263.077 73.4679L261.79 75.0673L426.527 207.628L427.814 206.029ZM432.026 214.826C432.026 211.407 430.477 208.172 427.814 206.029L426.527 207.628C428.706 209.382 429.974 212.028 429.974 214.826H432.026ZM432.026 430.7V214.826H429.974V430.7H432.026ZM420.735 441.991C426.971 441.991 432.026 436.936 432.026 430.7H429.974C429.974 435.802 425.837 439.938 420.735 439.938V441.991ZM307 441.991H420.735V439.938H307V441.991ZM305.974 334.661V440.964H308.026V334.661H305.974ZM281.339 310.026C294.944 310.026 305.974 321.056 305.974 334.661H308.026C308.026 319.922 296.078 307.974 281.339 307.974V310.026ZM235.661 310.026H281.339V307.974H235.661V310.026ZM211.026 334.661C211.026 321.056 222.056 310.026 235.661 310.026V307.974C220.922 307.974 208.974 319.922 208.974 334.661H211.026ZM211.026 440.964V334.661H208.974V440.964H211.026ZM91.2646 441.991H210V439.938H91.2646V441.991ZM79.9735 430.7C79.9735 436.936 85.0287 441.991 91.2646 441.991V439.938C86.1625 439.938 82.0265 435.802 82.0265 430.7H79.9735ZM79.9735 214.826V430.7H82.0265V214.826H79.9735ZM84.186 206.029C81.5225 208.172 79.9735 211.407 79.9735 214.826H82.0265C82.0265 212.028 83.2938 209.382 85.473 207.628L84.186 206.029Z"
        fill="url(#paint1_linear_723_3432)"
        fill-opacity="0.4"
        mask="url(#path-1-inside-1_723_3432)"
      />
    </g>
    <defs>
      <filter
        id="filter0_biiii_723_3432"
        x="70.7354"
        y="61.7354"
        width="370.529"
        height="391.547"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="5.13228" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_723_3432"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_723_3432"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3.07937" />
        <feGaussianBlur stdDeviation="5.13228" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_723_3432"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-5.13228" />
        <feGaussianBlur stdDeviation="9.26633" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_723_3432"
          result="effect3_innerShadow_723_3432"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.3175" />
        <feGaussianBlur stdDeviation="6.26871" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_innerShadow_723_3432"
          result="effect4_innerShadow_723_3432"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.02291" />
        <feGaussianBlur stdDeviation="1.56718" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_innerShadow_723_3432"
          result="effect5_innerShadow_723_3432"
        />
      </filter>
      <linearGradient
        id="paint0_linear_723_3432"
        x1="256"
        y1="72"
        x2="256.076"
        y2="440.964"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565A5D" />
        <stop offset="1" stop-color="#2B2B2B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_723_3432"
        x1="414.229"
        y1="440.966"
        x2="95.8737"
        y2="338.846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 22,
    },
  },
};
</script>
